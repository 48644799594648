@font-face {
    font-family: 'Gotham';
    src: url('../fonts/hinted-subset-Gotham-BoldItalic.woff2') format('woff2'),
        url('../fonts/hinted-subset-Gotham-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/hinted-subset-Gotham-Book.woff2') format('woff2'),
        url('../fonts/hinted-subset-Gotham-Book.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/hinted-subset-Gotham-Black.woff2') format('woff2'),
        url('../fonts/hinted-subset-Gotham-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}