@function rem($pxval) {
	@return ($pxval / $em-base) * 1rem;
}

@mixin breakpoint($point) {
	@if $point == laptop {
		@media (max-width: $media-l) { @content ; }
	}
	@else if $point == tablet {
		@media (max-width: $media-m) { @content ; }
	}
	@else if $point == mobile {
		@media (max-width: $media-xs)  { @content ; }
	}
}