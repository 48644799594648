body {
	color: #fff;
	line-height: 1.5;
	font-family: 'Gotham';
	font-weight: 500;
	min-height: 100vh;
	@include breakpoint(mobile) {
		background: url('../images/dist/bg.jpg') no-repeat center center / cover;
	}
}
a {
	color: #fff;
	text-decoration: none;
}
.video-container {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%; 
	overflow: hidden;
	@include breakpoint(mobile) {
		position: relative;
		height: inherit;
	}
	video {
		min-width: 100%; 
		min-height: 100%; 
		width: auto;
		height: auto;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%,0);
		@include breakpoint(mobile) {
			display: none;
		}
	}
	.overlay {
		position: absolute;
		min-height: 84vh;
		margin: 8vh auto 0 auto;
		padding: 0;
		left: 0;
		right: 0;
		width: 95%;
		max-width: rem(1256);
		box-sizing: border-box;
		@include breakpoint(mobile) {
			width: 90%;
			position: relative;
		}
	}
}

.site-header {
	text-align: center;
	@include breakpoint(mobile) {
		text-align: left;
		.logo {
			width: rem(190);
			margin-left: rem(10);
		}
	}
}
.site-content {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
	@include breakpoint(mobile) {
		position: relative;
		top: auto;
		transform: none;
	}
	h1 {
		font-size: rem(55);
		max-width: rem(880);
		margin: 0 auto;
		line-height: 1;
		font-weight: 400;
		@include breakpoint(mobile) {
			font-size: rem(38);
		}
	}
	p {
		text-align: right;
		padding-right: rem(210);
		font-size: rem(22);
		font-weight: 700;
		opacity: 0;
		animation: fadeIn .6s ease-in 1.7s;
		animation-fill-mode: forwards;
		@include breakpoint(laptop) {
			padding-right: 0;
		}
	}
	ul {
		display: flex;
		margin: 0px rem(-20) 0 rem(120);
		padding: 0;
		text-align: left;
		list-style-type: none;
		@include breakpoint(tablet) {
			display: block;
		}
		@include breakpoint(mobile) {
			margin: rem(50) 0 0 0;
		}
		li {
			padding: 0 0 rem(100) rem(12);
			background: url('../images/dist/dot.png') no-repeat left bottom;
			background-size: 40px;
			width: 110px;
    		line-height: 1.2;
			transition: .5s;
			@include breakpoint(mobile) {
				width: 100%;
			}
    		&:before {
				content: '';
				width: 80px;
				height: 3px;
				display: block;
				position: absolute;
				bottom: 19px;
				transform: translateX(-92px);
				border-bottom: 2px dashed #fff;
				transition: .5s;
				@include breakpoint(tablet) {
					display: none;
				}
			}
    		@include breakpoint(tablet) {
    			padding: rem(60) 0 0 rem(60);
				background: url('../images/dist/dot.png') no-repeat left top;
				position: relative;
			}
			@include breakpoint(mobile) {
				font-size: rem(14);
			}
			&:nth-child(1):before {
    			display: none;
    		}
			&:nth-child(3) {
    			width: 195px;
    		}
    		&:nth-child(4):before {
    			width: 165px;
    			transform: translateX(-177px);
			}
			&:nth-child(4) {
    			width: 90px;
			}
			&:nth-child(5):before{
    			width: 60px;
    			transform: translateX(-72px);
			}
			&:nth-child(6) {
    			width: 135px;
			}
			&:nth-child(7):before {
    			width: 105px;
    			transform: translateX(-117px);
			}
    		&:last-child {
    			background: url('../images/dist/dot.png') no-repeat left bottom;
    			flex-grow: 1.15;
    			@include breakpoint(tablet) {
    				background: url('../images/dist/dot.png') no-repeat left top;
				}
				span {
					max-width: 250px;
					margin-top: -19px;
					@include breakpoint(tablet) {
						max-width: 400px;
						margin-top: 0;
					}
				}
    		}
    		span {
    			position: absolute;
    			opacity: 1;
    			transition: .5s .375s;
    			
    			@include breakpoint(tablet) {
	    			top: rem(4);
				}
    		}
    		&.active {
    			+ li,
    			+ li + li,
    			+ li + li + li,
    			+ li + li + li + li,
    			+ li + li + li + li + li,
    			+ li + li + li + li + li + li,
				+ li + li + li + li + li + li + li {
    				opacity: 0;
    			    background-size: 0;
    			
		    		&:before {
						width: 0;
					}
					span {
						opacity: 0;
					}
    			
    				@include breakpoint(tablet) {
    					opacity: 1;
    					background-size: 40px;
    					
			    		&:before {
							width: 145px;
						}
    					span {
							opacity: 1;
    					}
    				}
    			}
    		}
		}
	}
}
.site-footer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
	font-size: rem(16);
	@include breakpoint(laptop) {
		padding-left: rem(200);
	}
	@include breakpoint(tablet) {
		padding-left: rem(120);
		font-size: rem(14);
		text-align: left;
	}
	@include breakpoint(mobile) {
		position: relative;
		font-size: rem(12);
		padding: rem(50) 0 rem(70) rem(90);
		letter-spacing: -.5px;
	}
	strong {
		font-weight: 900;
	}
}
.right {
	position: absolute;
	right: 0;
	animation: openUp 1s ease-out;
	@include breakpoint(laptop) {
		width: rem(150);
		animation: none;
	}
	@include breakpoint(tablet) {
		width: rem(100);
	}
	@include breakpoint(mobile) {
		width: rem(80);
	}
}
.left {
	position: absolute;
	left: 0;
	transform: translate(0,-145px);
	animation: openDown 1s ease-out;
	@include breakpoint(laptop) {
		width: rem(150);
		transform: translateY(-90px);
		animation: none;
	}
	@include breakpoint(tablet) {
		width: rem(100);
		transform: translateY(-40px);
	}
	@include breakpoint(mobile) {
		width: rem(80);
		transform: translateY(-32px);
	}
}
.group {
	position: absolute;
	right: 0;
	transform: translate(0,-70px);
	text-align: center;
	font-size: rem(12);
	@include breakpoint(tablet) {
		transform: translateY(-65px);
	}
	@include breakpoint(mobile) {
		transform: translateY(20px);
	}
}

@keyframes openDown {
    from {
		opacity: 0;
    	transform: translate(300px,-300px);
    }
    to {
    	opacity: 1;
    	transform: translate(0,-145px);
    }
}
@keyframes openUp {
    from {
		opacity: 0;
    	transform: translate(-300px,155px);
    }
    to {
    	opacity: 1;
    	transform: translate(0,0);
    }
}
@keyframes fadeIn {
    from {
    	opacity: 0;
    }
    to {
    	opacity: 1;
    }
}