/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Normalize
# Typography
# Header
# Footer
# Main
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0;
  max-width: 100%; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset,
legend {
  border: none;
  margin: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@font-face {
  font-family: 'Gotham';
  src: url("../fonts/hinted-subset-Gotham-BoldItalic.woff2") format("woff2"), url("../fonts/hinted-subset-Gotham-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/hinted-subset-Gotham-Book.woff2") format("woff2"), url("../fonts/hinted-subset-Gotham-Book.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/hinted-subset-Gotham-Black.woff2") format("woff2"), url("../fonts/hinted-subset-Gotham-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

/*--------------------------------------------------------------
# Site
--------------------------------------------------------------*/
body {
  color: #fff;
  line-height: 1.5;
  font-family: 'Gotham';
  font-weight: 500;
  min-height: 100vh; }
  @media (max-width: 34em) {
    body {
      background: url("../images/dist/bg.jpg") no-repeat center center/cover; } }

a {
  color: #fff;
  text-decoration: none; }

.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  @media (max-width: 34em) {
    .video-container {
      position: relative;
      height: inherit; } }
  .video-container video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0); }
    @media (max-width: 34em) {
      .video-container video {
        display: none; } }
  .video-container .overlay {
    position: absolute;
    min-height: 84vh;
    margin: 8vh auto 0 auto;
    padding: 0;
    left: 0;
    right: 0;
    width: 95%;
    max-width: 78.5rem;
    box-sizing: border-box; }
    @media (max-width: 34em) {
      .video-container .overlay {
        width: 90%;
        position: relative; } }

.site-header {
  text-align: center; }
  @media (max-width: 34em) {
    .site-header {
      text-align: left; }
      .site-header .logo {
        width: 11.875rem;
        margin-left: 0.625rem; } }

.site-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center; }
  @media (max-width: 34em) {
    .site-content {
      position: relative;
      top: auto;
      transform: none; } }
  .site-content h1 {
    font-size: 3.4375rem;
    max-width: 55rem;
    margin: 0 auto;
    line-height: 1;
    font-weight: 400; }
    @media (max-width: 34em) {
      .site-content h1 {
        font-size: 2.375rem; } }
  .site-content p {
    text-align: right;
    padding-right: 13.125rem;
    font-size: 1.375rem;
    font-weight: 700;
    opacity: 0;
    animation: fadeIn .6s ease-in 1.7s;
    animation-fill-mode: forwards; }
    @media (max-width: 62em) {
      .site-content p {
        padding-right: 0; } }
  .site-content ul {
    display: flex;
    margin: 0px -1.25rem 0 7.5rem;
    padding: 0;
    text-align: left;
    list-style-type: none; }
    @media (max-width: 48em) {
      .site-content ul {
        display: block; } }
    @media (max-width: 34em) {
      .site-content ul {
        margin: 3.125rem 0 0 0; } }
    .site-content ul li {
      padding: 0 0 6.25rem 0.75rem;
      background: url("../images/dist/dot.png") no-repeat left bottom;
      background-size: 40px;
      width: 110px;
      line-height: 1.2;
      transition: .5s; }
      @media (max-width: 34em) {
        .site-content ul li {
          width: 100%; } }
      .site-content ul li:before {
        content: '';
        width: 80px;
        height: 3px;
        display: block;
        position: absolute;
        bottom: 19px;
        transform: translateX(-92px);
        border-bottom: 2px dashed #fff;
        transition: .5s; }
        @media (max-width: 48em) {
          .site-content ul li:before {
            display: none; } }
      @media (max-width: 48em) {
        .site-content ul li {
          padding: 3.75rem 0 0 3.75rem;
          background: url("../images/dist/dot.png") no-repeat left top;
          position: relative; } }
      @media (max-width: 34em) {
        .site-content ul li {
          font-size: 0.875rem; } }
      .site-content ul li:nth-child(1):before {
        display: none; }
      .site-content ul li:nth-child(3) {
        width: 195px; }
      .site-content ul li:nth-child(4):before {
        width: 165px;
        transform: translateX(-177px); }
      .site-content ul li:nth-child(4) {
        width: 90px; }
      .site-content ul li:nth-child(5):before {
        width: 60px;
        transform: translateX(-72px); }
      .site-content ul li:nth-child(6) {
        width: 135px; }
      .site-content ul li:nth-child(7):before {
        width: 105px;
        transform: translateX(-117px); }
      .site-content ul li:last-child {
        background: url("../images/dist/dot.png") no-repeat left bottom;
        flex-grow: 1.15; }
        @media (max-width: 48em) {
          .site-content ul li:last-child {
            background: url("../images/dist/dot.png") no-repeat left top; } }
        .site-content ul li:last-child span {
          max-width: 250px;
          margin-top: -19px; }
          @media (max-width: 48em) {
            .site-content ul li:last-child span {
              max-width: 400px;
              margin-top: 0; } }
      .site-content ul li span {
        position: absolute;
        opacity: 1;
        transition: .5s .375s; }
        @media (max-width: 48em) {
          .site-content ul li span {
            top: 0.25rem; } }
      .site-content ul li.active + li,
      .site-content ul li.active + li + li,
      .site-content ul li.active + li + li + li,
      .site-content ul li.active + li + li + li + li,
      .site-content ul li.active + li + li + li + li + li,
      .site-content ul li.active + li + li + li + li + li + li,
      .site-content ul li.active + li + li + li + li + li + li + li {
        opacity: 0;
        background-size: 0; }
        .site-content ul li.active + li:before,
        .site-content ul li.active + li + li:before,
        .site-content ul li.active + li + li + li:before,
        .site-content ul li.active + li + li + li + li:before,
        .site-content ul li.active + li + li + li + li + li:before,
        .site-content ul li.active + li + li + li + li + li + li:before,
        .site-content ul li.active + li + li + li + li + li + li + li:before {
          width: 0; }
        .site-content ul li.active + li span,
        .site-content ul li.active + li + li span,
        .site-content ul li.active + li + li + li span,
        .site-content ul li.active + li + li + li + li span,
        .site-content ul li.active + li + li + li + li + li span,
        .site-content ul li.active + li + li + li + li + li + li span,
        .site-content ul li.active + li + li + li + li + li + li + li span {
          opacity: 0; }
        @media (max-width: 48em) {
          .site-content ul li.active + li,
          .site-content ul li.active + li + li,
          .site-content ul li.active + li + li + li,
          .site-content ul li.active + li + li + li + li,
          .site-content ul li.active + li + li + li + li + li,
          .site-content ul li.active + li + li + li + li + li + li,
          .site-content ul li.active + li + li + li + li + li + li + li {
            opacity: 1;
            background-size: 40px; }
            .site-content ul li.active + li:before,
            .site-content ul li.active + li + li:before,
            .site-content ul li.active + li + li + li:before,
            .site-content ul li.active + li + li + li + li:before,
            .site-content ul li.active + li + li + li + li + li:before,
            .site-content ul li.active + li + li + li + li + li + li:before,
            .site-content ul li.active + li + li + li + li + li + li + li:before {
              width: 145px; }
            .site-content ul li.active + li span,
            .site-content ul li.active + li + li span,
            .site-content ul li.active + li + li + li span,
            .site-content ul li.active + li + li + li + li span,
            .site-content ul li.active + li + li + li + li + li span,
            .site-content ul li.active + li + li + li + li + li + li span,
            .site-content ul li.active + li + li + li + li + li + li + li span {
              opacity: 1; } }

.site-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 1rem; }
  @media (max-width: 62em) {
    .site-footer {
      padding-left: 12.5rem; } }
  @media (max-width: 48em) {
    .site-footer {
      padding-left: 7.5rem;
      font-size: 0.875rem;
      text-align: left; } }
  @media (max-width: 34em) {
    .site-footer {
      position: relative;
      font-size: 0.75rem;
      padding: 3.125rem 0 4.375rem 5.625rem;
      letter-spacing: -.5px; } }
  .site-footer strong {
    font-weight: 900; }

.right {
  position: absolute;
  right: 0;
  animation: openUp 1s ease-out; }
  @media (max-width: 62em) {
    .right {
      width: 9.375rem;
      animation: none; } }
  @media (max-width: 48em) {
    .right {
      width: 6.25rem; } }
  @media (max-width: 34em) {
    .right {
      width: 5rem; } }

.left {
  position: absolute;
  left: 0;
  transform: translate(0, -145px);
  animation: openDown 1s ease-out; }
  @media (max-width: 62em) {
    .left {
      width: 9.375rem;
      transform: translateY(-90px);
      animation: none; } }
  @media (max-width: 48em) {
    .left {
      width: 6.25rem;
      transform: translateY(-40px); } }
  @media (max-width: 34em) {
    .left {
      width: 5rem;
      transform: translateY(-32px); } }

.group {
  position: absolute;
  right: 0;
  transform: translate(0, -70px);
  text-align: center;
  font-size: 0.75rem; }
  @media (max-width: 48em) {
    .group {
      transform: translateY(-65px); } }
  @media (max-width: 34em) {
    .group {
      transform: translateY(20px); } }

@keyframes openDown {
  from {
    opacity: 0;
    transform: translate(300px, -300px); }
  to {
    opacity: 1;
    transform: translate(0, -145px); } }

@keyframes openUp {
  from {
    opacity: 0;
    transform: translate(-300px, 155px); }
  to {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
